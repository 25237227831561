import React, { useState } from "react"
import { graphql } from "gatsby"
import Banner from "../components/banner/banner"
import Footer from "../components/footer/footer"
import Content from "../components/services/content"
import Services from "../components/services/services"
import Testimonial from "../components/testimonial/testimonial"
import Nav from "../components/navigation/navigation"
import SEO from "../components/seo"

const ServicesPage = ({ data }) => {
  const [showNav, setShowNav] = useState(false)
  const bgClass = "services"

  return (
    <div>
      <SEO title="Case Studies" />
      <Banner
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}
        title={data.strapiServicesList.Heading}
        summary={data.strapiServicesList.SubHeading}
        image={data.strapiServicesList.BannerImage.publicURL}
        bgClass="services"
        openNav={() => setShowNav(true)}></Banner>
      {showNav ? (
        <Nav
          logo={data.strapiGlobal.logo.publicURL}
          close={() => setShowNav(false)}></Nav>
      ) : null}
      <Content sections={data.strapiServicesList.Sections}></Content>
      <Services services={data.allStrapiService.edges}></Services>
      <Testimonial bgClass={bgClass}></Testimonial>
      <Footer
        logo={data.strapiGlobal.logo.publicURL}
        email={data.strapiGlobal.email}></Footer>
    </div>
  )
}

export default ServicesPage

export const pageQuery = graphql`
  query ServicesQuery {
    strapiGlobal {
      email
      logo {
        publicURL
      }
    }
    strapiServicesList {
      Heading
      SubHeading
      # SectionOneHeading
      # SectionOneBody
      # SectionTwoHeading
      # SectionTwoBody
      BannerImage {
        publicURL
      }
      Sections {
        Heading
        Body
        Image {
          publicURL
        }
      }
    }
    allStrapiService {
      edges {
        node {
          Title
          Summary
          Services {
            Name
          }
        }
      }
    }
  }
`
