import React from "react"
import { Link } from "gatsby"
import styles from "./banner.module.scss"
import Container from "../../components/container/container"
import MailIcon from "../../images/navbar/mail.svg"
import PhoneIcon from "../../images/navbar/phone.svg"
import BurgerIcon from "../../images/navbar/burger.svg"

const Banner = props => {
  return (
    <div>
      <div className={`${styles.banner} ${styles[props.bgClass]}`}>
        <Container className={styles.banner__container}>
          <div className={styles.banner__navbar}>
            <Link to="/">
              <img
                src={props.logo}
                data-sal="slide-right"
                data-sal-duration="1000"
                className={styles.banner__navbar__logo}
              />
            </Link>
            <div
              className={styles.banner__navbar__icons}
              data-sal="slide-up"
              data-sal-duration="1000">
              <a href="tel:01163183771">
                <img src={PhoneIcon} alt="Phone" />
              </a>
              <a href={`mailto:` + props.email}>
                <img src={MailIcon} alt="Mail" />
              </a>
              <img src={BurgerIcon} alt="Menu" onClick={props.openNav} />
            </div>
          </div>
          <div className={styles.banner__content}>
            <div
              className={styles.banner__content__text}
              data-sal="slide-right"
              data-sal-duration="1000">
              <h1>{props.title}</h1>
              <p>{props.summary}</p>
            </div>
            {props.image && (
              <img
                src={props.image}
                alt="Banner Image"
                data-sal="slide-left"
                data-sal-duration="1000"
                className={styles.banner__image}
              />
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Banner
