import React from "react"
import styles from "./content.module.scss"
import Container from "../../components/container/container"

const Content = props => {
  return (
    <div>
      <div className={styles.content}>
        <Container>
          <div className={styles.content__wrap}>
            {props.sections.map((section, key) => (
              <div key={key} className={styles.content__section}>
                <div
                  className={styles.content__section__text}
                  data-sal="slide-right"
                  data-sal-duration="1000">
                  <h2>{section.Heading}</h2>
                  <p>{section.Body}</p>
                </div>
                <div
                  className={styles.content__section__image}
                  data-sal="slide-left"
                  data-sal-duration="1000">
                  <img src={section.Image.publicURL} alt="test image" />
                </div>
              </div>
            ))}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Content
